export default function toCamelCase(strr, unit) {

    console.log("str", typeof strr,unit);
    
    // If strr is a number, return it without any changes
    if (!isNaN(Number(strr))) {
        return strr;
    }
    if (strr !== undefined && strr !== null && isNaN(strr)) {
        let str = strr.toLowerCase();
        
        // Replace multiple spaces and line breaks with a single space
        str = str.replace(/\s+/g, ' ');

        // Replace punctuation marks followed by a word character with the punctuation mark plus a space
        str = str.replace(/([.!?])(\w)/g, '$1 $2');

        // Replace multiple consecutive punctuation marks with a single space
        str = str.replace(/[.!?]{2,}/g, ' ');

        // Split the string into words
        const words = str.trim().split(/\s+/);

        // Capitalize the first letter of each word
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
        }

        // Join the words with spaces
        return words.join(' ');
    } else {
        return '';
    }
}
